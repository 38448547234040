<template>
  <div>
    <div class="mb-2">Ризик</div>

    <v-data-table
      v-if="flaw.risks.length"
      class="mb-3"
      :items="flaw.risks"
      :headers="auditRiskListHeaders"
      item-value="id"
      density="compact"
      color="red"
      :hide-default-footer="flaw.risks.length <= 10">
      <template #item.actions="{ item }">
        <b-action-menu
          :actions="[
            {
              title: 'Видалити',
              icon: 'mdi-delete',
              action: () => removeRisk(item.id),
              loading: $loading.isLoading(`removeRisk_${item.id}`),
              disabled: audit.statusId !== 2,
            },
          ]"></b-action-menu>
      </template>
    </v-data-table>
    <div v-else class="text-center text-disabled">Ризик відсутній</div>

    <div v-if="audit.statusId === 2" class="d-flex justify-end">
      <v-btn variant="plain" @click="createRisk">
        <v-icon class="mr-3">mdi-plus</v-icon>
        <span>Додати ризик</span>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, inject } from 'vue'
import { auditKey, updateAuditKey } from '@/pages/audit/single/injectionKeys'
import { auditRiskListHeaders } from '../headers'
import { openDialog } from '@/dialog'
import { handleAsync } from 'best-modules/plugins'
import axios from '@/plugins/axios'
import { urlAuditRiskDelete } from '@/utils/urls'
import { BActionMenu } from 'best-modules/components'

export default {
  name: 'AuditRiskList',
  components: { BActionMenu },
  props: {
    flawId: {
      type: Number,
    },
  },
  data: () => {
    return {
      auditRiskListHeaders,
    }
  },
  setup(props) {
    const updateAudit = inject(updateAuditKey)
    const audit = computed({
      get: () => inject(auditKey),
      set: v => updateAudit(v),
    })

    const flaw = computed({
      get: () => audit.value.flaws.find(f => f.id === props.flawId),
      set: v => {
        const updatedFlaw = audit.value.flaws.find(f => f.id === props.flawId)

        Object.assign(updatedFlaw, v)
      },
    })

    const createRisk = () => {
      openDialog({
        name: 'AuditRisk',
        dialogData: {
          flawId: props.flawId,
        },
        params: {
          cb: risk => {
            flaw.value.risks.push(risk)
          },
        },
      })
    }
    const removeRisk = (riskId: number) => {
      return handleAsync(`removeRisk_${riskId}`, () => {
        return axios.delete(urlAuditRiskDelete(riskId)).then(() => {
          flaw.value.risks = flaw.value.risks.filter(r => r.id !== riskId)
        })
      })
    }

    return { audit, flaw, createRisk, removeRisk }
  },
}
</script>

<style scoped lang="scss"></style>
