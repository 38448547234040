<template>
  <div>
    <b-select
      v-model="risk.riskTypeId"
      :items="$directory.get('riskTypes')"
      :loading="$loading.isLoading('riskTypes')"
      label="Вид ризику"
      :error-messages="getErrorMessages(v$.riskTypeId)"
      @focus="$directory.fill('riskTypes')"
      @blur="v$.riskTypeId.$touch()"></b-select>
    <b-select
      v-model="risk.riskLevelId"
      :items="$directory.get('riskLevels')"
      :loading="$loading.isLoading('riskLevels')"
      label="Рівень ризику"
      :error-messages="getErrorMessages(v$.riskLevelId)"
      @focus="$directory.fill('riskLevels')"
      @blur="v$.riskLevelId.$touch()"></b-select>
  </div>
</template>

<script lang="ts">
import { PropType, reactive } from 'vue'
import { Dialog } from '@/dialog'
import { getErrorMessages } from 'best-modules/utils'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from '@/plugins/axios/index.js'
import { urlAuditRiskCreate } from '@/utils/urls'

export default {
  name: 'AuditRisk',
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const risk = reactive({
      riskTypeId: null,
      riskLevelId: null,
      auditFlawId: props.dialog.dialogData.flawId,
    })

    const rules = {
      riskTypeId: { required: required },
      riskLevelId: { required: required },
    }

    const v$ = useVuelidate(rules, risk)

    const submit = () => {
      return axios.post(urlAuditRiskCreate(), risk)
    }

    return { risk, v$, submit }
  },
}
</script>

<style scoped lang="scss"></style>
