import { DataTableHeader } from 'best-modules/components'

const auditListHeaders: DataTableHeader[] = [
  {
    key: 'status.name',
    title: 'Статус',
    sortable: false,
  },
  {
    key: 'name',
    title: 'Назва',
    sortable: false,
  },
  {
    key: 'year',
    title: 'Рік',
    sortable: false,
  },
  {
    key: 'type.name',
    title: 'Тип',
    sortable: false,
  },
  {
    key: 'actions',
    title: 'Дії',
    sortable: false,
    align: 'end',
  },
]

const auditRecommendationListHeaders: DataTableHeader[] = [
  {
    key: 'status',
    title: 'Статус',
  },
  {
    key: 'task',
    title: 'Завдання',
  },
  {
    key: 'executor',
    title: 'Виконавець',
  },
  {
    key: 'actions',
    title: 'Дії',
    sortable: false,
    align: 'end',
  },
]
const auditRiskListHeaders: DataTableHeader[] = [
  {
    key: 'risk_type.name',
    title: 'Вид ризику',
  },
  {
    key: 'risk_level.name',
    title: 'Рівень ризику',
  },
  {
    key: 'actions',
    title: 'Дії',
    sortable: false,
    align: 'end',
  },
]

export {
  auditListHeaders,
  auditRecommendationListHeaders,
  auditRiskListHeaders,
}
