<template>
  <div>
    <v-card variant="flat">
      <div>
        <div class="d-flex justify-space-between align-center mb-3">
          <div class="label" style="font-size: 1.15rem">Факт недоліку</div>
          <div v-show="audit.statusId !== 3">
            <v-btn @click="createFlaw">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Додати факт недоліку
            </v-btn>
          </div>
        </div>

        <v-expansion-panels variant="accordion" flat>
          <v-expansion-panel v-for="(flaw, idx) in audit.flaws" :key="flaw.id">
            <v-expansion-panel-title
              :style="{
                'border-bottom':
                  idx < audit.flaws.length - 1 ? '1px solid #cfcfcf' : null,
              }">
              <span>{{ flaw.text }}</span>
              <template #actions="{ collapseIcon, expandIcon, expanded }">
                <div class="d-flex align-center gap-3">
                  <v-btn
                    v-show="audit.statusId === 2"
                    icon="mdi-pencil"
                    density="compact"
                    variant="text"
                    rounded="circle"
                    @click.stop="updateFlaw(flaw)"></v-btn>
                  <v-icon :icon="expanded ? collapseIcon : expandIcon"></v-icon>
                </div>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text
              :style="{
                'border-bottom':
                  idx < audit.flaws.length - 1 ? '1px solid #cfcfcf' : null,
              }">
              <base-textarea
                :model-value="flaw.factorFlaw"
                :readonly="true"
                label="Опис"></base-textarea>

              <v-divider class="mb-3"></v-divider>

              <audit-recommendation-list
                :flaw-id="flaw.id"></audit-recommendation-list>

              <v-divider class="mb-3"></v-divider>

              <audit-risk-list :flaw-id="flaw.id"></audit-risk-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-if="!audit.flaws.length" class="text-center text-disabled">
          Факти недоліку відсутні
        </div>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { computed, inject } from 'vue'
import { auditKey, updateAuditKey } from './injectionKeys'
import { openDialog } from '@/dialog'
import AuditRecommendationList from './AuditRecommendationList.vue'
import AuditRiskList from './AuditRiskList.vue'
import { AuditFlaw } from '@/utils/types/audit'
import { setSnackbar } from 'best-modules/plugins'

export default {
  name: 'AuditFlawList',
  components: { AuditRecommendationList, AuditRiskList },
  setup() {
    const updateAudit = inject(updateAuditKey)
    const audit = computed({
      get: () => inject(auditKey),
      set: v => updateAudit(v),
    })

    const createFlaw = () => {
      if (audit.value.statusId !== 2) {
        return setSnackbar({
          color: 'warning',
          text: 'Щоб додати факт недоліку аудит потрібно взяти в роботу',
        })
      }
      openDialog({
        name: 'AuditFlaw',
        action: 'create',
        dialogData: {
          auditId: audit.value.id,
        },
        params: {
          cb: flaw => {
            audit.value.flaws.push({ ...flaw, recommendations: [] })
          },
        },
      })
    }

    const updateFlaw = (flaw: AuditFlaw) => {
      openDialog({
        name: 'AuditFlaw',
        action: 'update',
        dialogData: {
          flaw,
        },
        params: {
          cb: updatedFlaw => {
            audit.value.flaws = audit.value.flaws.map(f => {
              return f.id === flaw.id
                ? { ...updatedFlaw, recommendations: f.recommendations }
                : f
            })
          },
        },
      })
    }

    return { audit, createFlaw, updateFlaw }
  },
}
</script>

<style scoped lang="scss"></style>
